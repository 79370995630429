.Footer{
    background-color: white;
}

.FooterTop{
    display: flex;
    justify-content: space-evenly;
}

.FooterTop>div{
    display: flex;
    flex-direction: column;
}

.FooterTop>div p{
    font-size: 20px;
    font-weight: 600;
}

.Footer a{
    text-decoration: none;
    color: black;
}

.Footer a:hover{
    text-decoration: underline;
}

.line{
    width: 80%;
    height: 1px;
    border-top: 1px solid rgb(149, 149, 149);
    margin: 20px auto;
}

.FooterMiddle {
    display: flex;
    justify-content: center;
}

.FooterMiddle .socialIcon{
    width: 50px;
    height: 50px;
    border: 2px solid black;
    border-radius: 10px;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.socialIcon img{
    width: 30px;
    height: 30px;
}

.FooterBottom{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.FooterBottom img{
    width: 20%;
}

.copyright{
    background-color: black;
    color: white;
    font-size: 14px;
    padding: 20px 0;
    text-align: center;
}

.copyright a{
    color: white;
}
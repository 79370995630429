.CartIcon{
    position: relative;
    /* display: inline; */
    width: 30px;
    height: 100%;
}

.CartIcon>div{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 100%;
}

.CartIcon span{
    position: relative;
    top: -10px;
    font-size: 18px;
    color: #f08804;
    background-color: #82F8DD;
}

.CartIcon i{
    font-size: 30px;
    font-weight: 100;
}
.Banner {
    width: 100%;
    height: 60vh;
    margin-top: 100px;
    position: relative;
}

.Banner::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: calc(100% - 80px); /* Adjust the value of '50px' to control the height of the blurred section */
    background-image: linear-gradient(to bottom, transparent, #f1f5f7);
    /* backdrop-filter: blur(5px); */
    z-index: 1;
  }
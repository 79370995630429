.TextPosition{
    position: absolute;
    right: 0;
    top: 5%;
    width: 15vw;
    height: 70vh;
    border: 1px solid gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.zoom, .width, .align {
    width: 100%;
    text-align: center;
}

.zoom>div, .width>div, .align>div{
    text-align: left;
    padding: 3px;
    font-size: 14px;
}

.align button{
    margin:0 2px;
}

.align .selectedOption{
    border-color: rgb(100,100,100);
}

.TextPosition .move{
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.TextPosition button{
    padding: 5px 10px;
    font-size: 18px;
    cursor: pointer;
    border: 1px solid rgb(200,200,200);
    border-radius: 2px;
}

.TextPosition .leftAndRight{
    width: 10vw;
    display: flex;
    justify-content: space-between;
}

.colours{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 22vh;
}

.colourDivs {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
    justify-content: space-evenly;
}

.colourDivs div{
    margin: 2px;
    border: 1px solid gray;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.colourInputGroup{
    display: flex;
    justify-content: space-evenly;
}

.colourInputGroup input{
    width: 60%;
}

.colourInputGroup button{
    width: 25px;
    height: 25px;
    padding: 0;
    background-color: white;
    border-radius: 50%;
    color: dodgerblue;
    border:1px solid dodgerblue;
}
.Search {
    width: 40%;
    height: 70%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    border: 2px solid transparent;
    border-radius: 4px;
}

.inputFocus{
    border: 2px solid #ff9900;
}

.Search input{
    background-color: white;
    border: 1px solid white;
    height: 100%;
    width: 90%;
    border-radius: 4px 0 0 4px;
    box-sizing: border-box;
    padding-left: 10px;
}

.Search input:focus{
    outline: none;
}

.Search button {
    background-color: #fcb963;
    color: black;
    height: 100%;
    width: 10%;
    border: 1px solid #febd69;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    font-size: 20px;
}
.Position{
    position: absolute;
    right: 0;
    top: 10%;
    width: 15vw;
    height: 30vh;
    border: 1px solid gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.Position .move{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Position button{
    padding: 5px 10px;
    font-size: 18px;
    cursor: pointer;
}

.Position .leftAndRight{
    width: 10vw;
    display: flex;
    justify-content: space-between;
}
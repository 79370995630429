:root {
  --font: #36454F;
}

.strip2Mobile {
    display: none;
}

.Navbar{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
}

.Navbar a{
    text-decoration: none;
}

.Navbar a:hover{
    text-decoration: none;
}

.Navbar .strip1{
    background-image: linear-gradient(to right, #59f8d2, #88f9df);
    height: 60px;
    width: 100%;
    color: var(--font);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 14px;
    /* padding: 0 50px; */
    box-sizing: border-box;
}

.Navbar .strip1 a{
    color: var(--font);
}

.strip1 .logo img {
    /* width: 150px; */
    height: 54px;
    border: 2px solid transparent;
    background-color: white;
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
}

.strip1 .logo:hover{
    border: 2px solid black;
}

.location {
    display: flex;
    height: 54px;
    box-sizing: border-box;
    padding: 5px 10px;
    border: 2px solid transparent;
    cursor: pointer;
}

.location:hover{
    border: 2px solid black;
    border-radius: 5px;
}

.locationIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}

.locationIcon i{
    position: relative;
    top: 4px;
}

.locationText{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.smallText{
    line-height: 16px;
}

.largeText{
    font-size: 17px;
    line-height: 17px;
}

.boldText{
    font-weight: 600;
    font-size: 19px;
}

.signupButton a{
    display: flex;
    flex-direction: column;
    border: 2px solid transparent;
    border-radius: 5px;
    height: 54px;
    box-sizing: border-box;
    padding: 5px 10px;
}

.signupButton a:hover{
    border: 2px solid black;
}

.loginButton a{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid transparent;
    border-radius: 5px;
    height: 54px;
    box-sizing: border-box;
    padding: 5px 10px;
}

.loginButton a:hover{
    border: 2px solid black;
}

.loginButton i{
    font-size: 20px;
}

.ordersButton a{
    display: flex;
    flex-direction: column;
    border: 2px solid transparent;
    border-radius: 5px;
    height: 54px;
    box-sizing: border-box;
    padding: 5px 10px;
}

.ordersButton a:hover{
    border: 2px solid black;
}

.cartLink{
    border: 2px solid transparent;
    border-radius: 4px;
    height: 54px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.cartLink:hover{
    border: 2px solid black;
}

.cartLink a {
    display: flex;
    align-items: center;
    position: relative;
    top: -3px;
}

.cartLink a:hover{
    text-decoration: none;
}

.cartLink>a>.cartSpan{
    position: relative;
    top: 7px;
    font-size: 18px;
}

.Navbar .strip2{
    background-image: linear-gradient(to right, #43cfba, #80d4c7);
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 16px;
    box-sizing: border-box;
    color: var(--font);
}

.strip2>div, .strip2>a, .strip2>span{
    border: 2px solid transparent;
    border-radius: 4px;
    height: 36px;
    box-sizing: border-box;
    padding: 0 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-bottom: 2px;
}

.strip2>div:hover, .strip2>a:hover, .strip2>span:hover{
    border: 2px solid black;
}

.Navbar .strip2 a{
    color: var(--font);
}

.strip2 .allButton{
    display: flex;
    align-items: center;
}

.allButton i{
    font-size: 24px;
    -webkit-text-stroke: 1px;
}

.contactUs a{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    position: relative;
    top: 2px;
}

.contactUs span{
    margin: 0;
    padding: 0;
    position: relative;
    top: -5px;
    font-size: 14px;
}

@media only screen and (max-width:768px) {
    .Navbar .strip1 {
        flex-wrap: wrap;
        padding: 10px 0;
        height: 80px;
    }

    .Navbar .strip2{
        display: none;
    }

    .strip2Mobile{
        display: block;
        background-image: linear-gradient(to right, #59f8d2, #88f9df);
        height: 50px;
        width: 100%;
        font-size: 18px;
        box-sizing: border-box;
        color: var(--font);
    }
}
.Toolbar{
    width: 100%;
    height: 10vh;
    border-bottom: 1px solid gray;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    align-items: center;
    box-sizing: border-box;
}

.Toolbar button {
    border: 1px solid transparent;
    background-color: white;
    font-size: 16px;
    height: 6vh;
    transition: border 0.3s, background-color 0.3s;
}

.Toolbar button:enabled{
    cursor: pointer;
}

.Toolbar .center button:enabled:hover, .Toolbar .right button:enabled:hover{
    border: 1px solid #9DB2BF;
    background-color: #9DB2BF;
}

.Toolbar .left button:hover{
    border-bottom: 1px solid rgb(253, 83, 53);
}

.Toolbar .left button{
    color: tomato;
}

.Toolbar .center button {
    margin: 0 10px;
}

.Toolbar .right button {
    padding: 0 20px;
}

.inputForFile {
    width: 0;
    height: 0;
    position: absolute;
}
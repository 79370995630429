.ProductCard{
    display: flex;
    flex-direction: column;
    margin: 20px 0.25vw;
}

.productImage{
    width: 22vw;
    height: 16.5vw;
}

.ProductCard .discount {
    text-decoration: line-through;
}

.ProductCard .title {
    width: 22vw;
    font-size: 14px;
}

.ProductCard .owner{
    font-size: 14px;
    font-weight: 600;
    color: #595959;
    margin: 10px 0;
}

.price {
    font-size: 18px;
    font-weight: 600;
}

.discount, .discountPerc {
    color: #258635;
    font-size: 13px;
}

.ProductCard button {
    width: 55%;
    background-color: transparent;
    border: 3px solid black;
    border-radius: 20px;
    height: 40px;
    font-weight: 600;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 15px;
    cursor: pointer;
    transition: transform 0.3s, border-color 0.3s, color 0.3s;
}

.ProductCard button i {
    font-size: 26px;
}

.ProductCard button:hover{
    transform: scale(1.05);
    border-color: #36454F;
    color: #36454F;
}

.title{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.Area {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

.Area .graphic{
    height: 80vh;
    display: inline-block;
    position: relative;
}

.graphic .tshirt{
    height: 100%;
    width: 601px;
}

.addedImage {
    position: absolute;
    border: 1px solid transparent;
    width: 50%;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
}

.selectedImage {
    border: 1px dashed black;
    cursor: move;
}

.addedTextInput{
    position: absolute;
    width: 50%;
    background-color: transparent;
    border: 1px dashed black;
    outline: none;
    font-size: 20px;
    padding: 0;
    top:25%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    resize: none;
    overflow: hidden;
    height:auto;
    transition: height 0.3s;
    cursor: move;
    max-height: 70%;
}

.addedTextSpan{
    position: absolute;
    border: 1px solid transparent;
    width: 50%;
    background-color: transparent;
    font-size: 20px;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    white-space: pre-line;
}